.quote {
  display: flex;
  justify-content: center;
  position: relative;
  font-size: 32px;
  line-height: 1.5em;
  font-style: italic;
  margin: 0;
}

@media screen and (max-width: 700px) {
  .quote {
    font-size: 18px;
  }
}

.quote::before {
  content: '\201C';
  position: absolute;
  top: 0.25em;
  left: -0.15em;
  color: #6b6b6b;
  opacity: 0.4;
  font-size: 6em;
}