@import url(https://fonts.googleapis.com/css?family=Sanchez:400italic, 400);

.App {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
body {
  margin: 0;
  width: 100%;
  /* background-color: #ededed; */
  font-family: "Sanchez", serif;
  color: #595959;
}
@media (max-width: 700px) {
  body,
  .App {
    width: 100vw;
  }
}
h1 {
  font-family: "Sanchez", serif;
  color: #385903;
  margin: 0;
  padding: 10px 0;
}
button {
  font-family: "Sanchez", serif;
  background-color: #385903;
  color: white;
  padding: 15px;
  border-radius: 10px;
  font-size: 18px;
  cursor: pointer;
}

@media screen and (max-width: 700px) {
  button {
    padding: 10px;
    font-size: 18px;
  }
}

input {
  font-family: "Sanchez", serif;
  width: calc(100% - 22px);
  border-radius: 5px;
  padding: 10px;
  font-size: 18px;
}

textarea {
  font-family: "Sanchez", serif;
  width: calc(100% - 22px);
  border-radius: 5px;
  padding: 10px;
  font-size: 18px;
}

textarea:focus,
input:focus {
  outline: none;
}
a {
  outline: none;
  color: white;
  text-align: center;
  text-decoration: none;
}
b {
  color: #385903;
}
