@import url(https://fonts.googleapis.com/css?family=Sanchez:400italic, 400);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  width: 100%;
  height: 100%;
  max-width: 100%;
}
body {
  margin: 0;
  width: 100%;
  /* background-color: #ededed; */
  font-family: "Sanchez", serif;
  color: #595959;
}
@media (max-width: 700px) {
  body,
  .App {
    width: 100vw;
  }
}
h1 {
  font-family: "Sanchez", serif;
  color: #385903;
  margin: 0;
  padding: 10px 0;
}
button {
  font-family: "Sanchez", serif;
  background-color: #385903;
  color: white;
  padding: 15px;
  border-radius: 10px;
  font-size: 18px;
  cursor: pointer;
}

@media screen and (max-width: 700px) {
  button {
    padding: 10px;
    font-size: 18px;
  }
}

input {
  font-family: "Sanchez", serif;
  width: calc(100% - 22px);
  border-radius: 5px;
  padding: 10px;
  font-size: 18px;
}

textarea {
  font-family: "Sanchez", serif;
  width: calc(100% - 22px);
  border-radius: 5px;
  padding: 10px;
  font-size: 18px;
}

textarea:focus,
input:focus {
  outline: none;
}
a {
  outline: none;
  color: white;
  text-align: center;
  text-decoration: none;
}
b {
  color: #385903;
}

.quote {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  position: relative;
  font-size: 32px;
  line-height: 1.5em;
  font-style: italic;
  margin: 0;
}

@media screen and (max-width: 700px) {
  .quote {
    font-size: 18px;
  }
}

.quote::before {
  content: '\201C';
  position: absolute;
  top: 0.25em;
  left: -0.15em;
  color: #6b6b6b;
  opacity: 0.4;
  font-size: 6em;
}
